import './HeroSection.css';

function HeroSection() {
    return (
        <>
            <div id="home" className="hero-container">
                <div className="hero-background">
                    <h1 className="hero-header">JASON ASAHARA</h1>
                    <h1 className="hero-subheader">FRONT END DEVELOPER</h1>
                </div>
            </div>   
        </>
    )
}

export default HeroSection
