/* import photo from './images/macbook-opt.png'; */

const data = [
  {
    id: 1,
    title: 'HomeFinder App',
    description:
      'A real estate app that allows sellers to list and visitors to view property listings in order to buy/sell/rent their properties.',
    tools:
      'React, JavaScript, Tailwind CSS, HeadlessUI, Vercel, Google OAuth, Firebase, Firestore, Leaflet, Swiper',
    altSide: 'Weather App Side',
    mainClass: 'homefinder-app',
    sideImage: './images/macbookHomeFinder-opt.webp',
    projectUrl: 'https://home-finder-app-ja.vercel.app/',
    github: 'https://github.com/Ansel291/homeFinderApp',
    ctaColor: 'orange',
  },
  {
    id: 2,
    title: 'Weather App',
    description:
      'A weather app that searches for live weather conditions by city or zip code with 3-day forecast and 24-hour temperatures.',
    tools: 'React, JavaScript, Tailwind CSS, DaisyUI, Vercel',
    altSide: 'Weather App Side',
    mainClass: 'weather-app',
    sideImage: './images/macbookWeatherApp-opt.jpg',
    projectUrl: 'https://weather-app-ja.vercel.app/',
    github: 'https://github.com/Ansel291/weatherApp',
    ctaColor: 'grey',
  },
  {
    id: 3,
    title: 'Package Tracker',
    description:
      'A crud app that helps a company keep track of the status of their packages that is being shipped to their clients.',
    tools: 'React, JavaScript, CSS, Netlify',
    altSide: 'Package Tracker Side',
    mainClass: 'package-tracker',
    sideImage: './images/macbookPackageTracker-opt.jpg',
    projectUrl: 'https://package-tracker-ja.netlify.app/',
    github: 'https://github.com/Ansel291/packageTrackerApp/tree/jsonServer',
    ctaColor: 'blue',
  },
  {
    id: 4,
    title: 'Travel Blog',
    description:
      'A photography travel blog detailing my recent travels in order to document the places I have travelled to recently.',
    tools: 'React, JavaScript, CSS, Netlify',
    altSide: 'Travel Blog Side',
    mainClass: 'travel-blog',
    sideImage: './images/macbookTravelBlogHero-opt.jpg',
    projectUrl: 'https://photo-blog-ja-v2.netlify.app/',
    github: 'https://github.com/Ansel291/photoBlog-2021-12-28',
    ctaColor: 'blue',
  },
  {
    id: 5,
    title: 'Wanted: Dead or Alive',
    description:
      'A timed two-player memory game that involves matching the most famous lawmen/outlaws in the history of the American West.',
    tools: 'CSS3, JavaScript, JQuery, Heroku',
    altSide: 'Wanted Dead or Alive Side',
    mainClass: 'wanted-dead',
    sideImage: './images/macbook-opt.jpg',
    projectUrl: 'https://ansel291.github.io/Project_1/',
    github: 'https://github.com/Ansel291/Project_1',
    ctaColor: 'orange',
  },
  {
    id: 6,
    title: 'Restful Crawl',
    description:
      'Co-Developed Restful Crawl, a group project where our team created an App that allows users to search restaurants, and create a restaurant itinerary to be shared with their friends by replacing text-heavy content with images and maps.',
    tools:
      'AJAX, Express, GeoNames, Google API, Google OAuth, Javascript, JSON, MongoDB, Mongoose, Node.js, Yelp API',
    altSide: 'Restful Crawl Side',
    mainClass: 'restful-crawl',
    sideImage: './images/macbookRestfulCrawl-opt.jpg',
    projectUrl: 'https://fast-brook-3166.herokuapp.com/',
    github: 'https://github.com/pujoey/Resfulcrawl',
    ctaColor: 'orange',
  },
  {
    id: 7,
    title: 'Local City',
    description:
      'An App that allows users to search for points of interest on a map and to have each location displayed, and lists an index with a description that matches each location. It is currently in alpha stage of development.',
    tools: 'FourSquare API, Google Maps API, Express, Angular.js, Node.js',
    altSide: 'Local City Side',
    mainClass: 'local-city',
    sideImage: './images/macbookLocalCity-opt.jpg',
    projectUrl: 'https://secure-ravine-3320.herokuapp.com/',
    github: 'https://github.com/Ansel291/local_city',
    ctaColor: 'red',
  },
]

export default data
